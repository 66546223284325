@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&family=Train+One&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}
body {
  font-family: "Roboto", "Montserrat", sans-serif;
}

@font-face {
  font-family: "nunitoBd";
  src:
    local("Nunito-Bold"),
    url("./assets/fonts/Nunito/Nunito-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "nunitoExtraBd";
  src:
    local("Nunito-ExtraBold"),
    url("./assets/fonts/Nunito/Nunito-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "nunitoSemiBd";
  src:
    local("Nunito-SemiBold"),
    url("./assets/fonts/Nunito/Nunito-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "nunitoMedium";
  src:
    local("Nunito-Medium"),
    url("./assets/fonts/Nunito/Nunito-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "quicksandMedium";
  src:
    local("Quicksand-Medium"),
    url("./assets/fonts/Quicksand/Quicksand-Medium.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "quicksandRegular";
  src:
    local("Quicksand-Regular"),
    url("./assets/fonts/Quicksand/Quicksand-Regular.ttf") format("truetype");
  font-display: swap;
}

@layer utilities {
  .maxContainer {
    @apply mx-auto max-w-[1400px];
  }

  .flexCenter {
    @apply flex items-center justify-center;
  }

  .flexBetween {
    @apply flex items-center justify-between;
  }

  .flexStart {
    @apply flex items-center justify-start;
  }

  .flexEnd {
    @apply flex items-center justify-end;
  }
}

.no-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.slider-component {
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.slick-next:before {
  display: none;
}
.slick-prev:before {
  display: none;
}
.slick-prev {
  z-index: 10000;
}
.MuiPaper-root {
  padding-right: 0 !important;
}
